*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body{
  background-color: #000000;
 
}

a:hover{
  cursor: pointer;
   transform: translateY(-5%);
    color: #ff9c41 !important;
  
}

.CustomText:hover{
  color: #ff9c41 !important;
  -webkit-text-stroke: 2px #ff9c41;
}

a {
  text-decoration: none;
  scroll-behavior: smooth;
}

p{
  color: #ffffff;
  font-family: "Montserrat";
}

.Next-Level-Gift{
  max-width: 100%;
} 

@media (max-width:1100px){
  .Next-Level-Gift{
    width: 117%;
    max-width: 131%;
    margin-left: 10px;
  } 
}