
.SelectedWork-Container{
.SelectedWorkParagraph-Container{
display: flex;
justify-content: flex-end;
align-items: center;
.SelectedWork-Text-Specific{
line-height: 20.35px;}
}
}
.Selector-De-Imagenes-Content{
    .grid-content-selected {
        &.left{
           .Texto-boton-SelextedWork{
               width:72% ;
               @media (max-width:1100px) {
                   width: 96%;
               }
           }
        }
    }
}





